import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import ToolPage from './pages/ToolPage';
import PermissionsPage from './pages/PermissionsPage';
import Navigation from './components/Navigation';


export default function App() {
  return (
    <Router>
      <main>
        <Navigation />
        <Switch>
          <Route exact={true} path='/permissions'>
            <PermissionsPage />
          </Route>
          <Route exact={true} path='/'>
            <ToolPage />
          </Route>
          <Route path='*'>
            <Redirect to={'/'} />
          </Route>
        </Switch>
      </main>
    </Router>
  )
}
