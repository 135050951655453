import React from 'react';

type Props = {
  code: string;
};

const Debug: React.FC<Props> = ({ code }) => {
  if (code) {
    return (
      <>
        <h5 className='h5'>Debug</h5>
        <code>{code}</code>
      </>
    );
  }
  return null;
};

export default Debug;
