import React from 'react';
import styles from './Heading.module.scss';

type Props = {
  heading: string;
  subheading?: string;
  isLabel?: boolean;
  labelFor?: string;
};

const Heading: React.FC<Props> = ({
  isLabel = false,
  heading,
  subheading,
  labelFor,
}) => (
  <div className={styles.container}>
    {isLabel ? (
      <label htmlFor={labelFor}>
        <h3 className={`h4 ${styles.heading}`}>{heading}</h3>
      </label>
    ) : (
      <h3 className={`h4 ${styles.heading}`}>{heading}</h3>
    )}
    <span className={`h5 ${styles.subheading}`}>{subheading}</span>
  </div>
);

export default Heading;
