import React from 'react';
import styles from './Button.module.scss';
import classnames from 'classnames';

type Props = {
  children: string | React.ReactChildren;
  className?: string;
  isDisabled?: boolean;
  isPrimary?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button: React.FC<Props> = ({
  children,
  className,
  isDisabled,
  isPrimary = false,
  onClick,
}) => {
  let cn = classnames(
    className,
    styles.base,
    isPrimary ? styles.primary : styles.secondary
  );

  return (
    <button
      className={cn}
      type='button'
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
