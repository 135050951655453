import React from 'react';
import Button from '../components/Button';
import Heading from '../components/Heading';

const PermissionsPage = () => {
  const onPermissionsBtnClick = () => {
    for (let i = 0; i < 3; i++) {
      let w = window.open('/', '_blank');
      if (w) {
        setTimeout(w => {
          w.close();
        }, 100);
      }
    }
  };

  return (
    <>
      <section>
        <h1>How to allow pop-up permissions</h1>
      </section>

      <section>
        <Heading
          heading='1. Click to open dummy pages'
          subheading={
            'Batchurlor will open multiple tabs and immediately close them to trigger a response from the browser'
          }
        />
        <Button isPrimary onClick={onPermissionsBtnClick}>
          Trigger permissions check
        </Button>
      </section>

      <section>
        <Heading heading='2. Click on pop-up blocker icon in the browser address bar' />
        <img
          src='/assets/images/permissions/pop-up-blocked.png'
          alt='pop-up blocked'
        />
      </section>

      <section>
        <Heading
          heading={`3. Always allow pop-ups and redirects from ${window.location.origin}`}
        />
        <img
          src='/assets/images/permissions/allow-pop-ups.png'
          alt='allow pop-ups'
        />
      </section>
    </>
  );
};

export default PermissionsPage;
