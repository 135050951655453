import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { STATE_HOST, STATE_PATHS, getState, saveState } from '../utils/state';
import Table from '../components/Table';
import Button from '../components/Button';
import Heading from '../components/Heading';
import Debug from '../components/Debug';
import HostInput from '../components/HostInput';
import PathsInput from '../components/PathsInput';

type UrlParams = {
  host: string;
  paths: string[];
};

const ToolPage = () => {
  // Parse query params
  const query: any = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const hostParam = query.host;
  const pathsParam = query.paths;

  // Initialise state
  const [shareableUrl, setShareableUrl] = useState('');
  const [host, setHost] = useState(
    hostParam ? hostParam : getState(STATE_HOST) || ''
  );
  const [pathsInputValue, setPathsInputValue] = useState(
    pathsParam ? pathsParam.join('\n') : getState(STATE_PATHS) || ''
  );
  const [pathsList, setPathsList] = useState(pathsParam || []);

  useEffect(() => {
    saveState(STATE_HOST, host);
  }, [host]);

  // Parse paths list
  useEffect(() => {
    let pathsArray = pathsInputValue.split(/\n/);

    // Resolve slashes, hashes, queries and subdomains
    pathsArray = pathsArray.map((item: string) => {
      const hostLastChar = host[host.length - 1];
      const itemFirstChar = item[0];
      if (
        !(itemFirstChar === '/' || hostLastChar === '/') &&
        !(itemFirstChar === '#' || hostLastChar === '#') &&
        !(itemFirstChar === '?' || hostLastChar === '?') &&
        !(itemFirstChar === '.' || hostLastChar === '.')
      ) {
        item = '/' + item;
      }
      return item;
    });
    setPathsList(pathsArray);
    saveState(STATE_PATHS, pathsInputValue);
  }, [pathsInputValue, host]);

  // Open tabs
  const onOpenBtnClick = (host: string, paths: string[]) => {
    const links = paths.map(path => {
      // add https:// prefix if host does not include protocol
      if (!host.match(/^[a-zA-Z]+:\/\//)) {
        return 'https://' + host + path;
      }

      return host + path;
    });

    links.map(link => {
      window.open(link, '_blank');
      return null;
    });
  };

  // Get shareable link
  const onShareBtnClick = () => {
    let urlObj: UrlParams = { host: host, paths: [] };
    urlObj.paths = pathsList;

    setShareableUrl(qs.stringify(urlObj));

    if (navigator.clipboard) {
      navigator.clipboard.writeText(
        window.location.origin + '?' + qs.stringify(urlObj)
      );
    }
  };

  return (
    <>
      <section>
        <h1>Create batch</h1>
      </section>

      <section>
        <Heading
          heading='1. Set host'
          subheading={"example: 'google.com'"}
          isLabel={true}
          labelFor='hostInput'
        />
        <HostInput value={host} onChange={setHost} />
      </section>

      <section>
        <Heading
          heading='2. Add paths / params'
          subheading={"example: '/search', '?query=hello'"}
          isLabel={true}
          labelFor='pathInput'
        />
        <PathsInput
          value={pathsInputValue}
          onChange={setPathsInputValue}
          rows={pathsList.length + 2}
        />
      </section>

      <section>
        <Heading
          heading='3. Review'
          subheading='Confirm urls have been constructed correctly'
        />
        <Table host={host} paths={pathsList} />
      </section>

      <section>
        <Heading heading='4. Open/Share' />
        <Button
          isPrimary
          isDisabled={!host || !pathsList.length}
          onClick={() => onOpenBtnClick(host, pathsList)}
        >
          Open links
        </Button>
        <Button
          isDisabled={!host || !pathsList.length}
          onClick={onShareBtnClick}
        >
          Get shareable url
        </Button>
      </section>

      <section>
        <Debug code={shareableUrl} />
      </section>
    </>
  );
};

export default ToolPage;
