import React from 'react';
import classnames from 'classnames';
import styles from './Table.module.scss';

type Props = {
  host: string;
  paths: string[];
};

const Table: React.FC<Props> = ({ host, paths }) => {
  return (
    <table className={styles.table} cellSpacing={0} cellPadding={0}>
      <thead className={styles.header}>
        <tr className={styles.row}>
          <td className={classnames(styles.data, styles.dataIndex)}>#</td>
          <td className={classnames(styles.data, styles.dataHost)}>Host</td>
          <td className={classnames(styles.data, styles.dataPath)}>Path</td>
        </tr>
      </thead>
      <tbody>
        {paths.map((item, index) => {
          return (
            <tr key={`${item}-${index}`} className={styles.row}>
              <td className={classnames(styles.data, styles.dataIndex)}>
                {index + 1}
              </td>
              <td className={classnames(styles.data, styles.dataHost)}>
                <span className={styles.item}>{host}</span>
              </td>
              <td className={classnames(styles.data, styles.dataPath)}>
                <span className={styles.item}>{item}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
