import React from 'react';
import styles from './Navigation.module.scss';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className={styles.nav}>
      <div>
        {/* <img src='/assets/logo.svg' alt='logo'></img> */}
        <h3>Batchurlor</h3>
      </div>
      <ul>
        <li>
          <NavLink
            className={`h5 ${styles.link}`}
            isActive={() => window.location.pathname === '/'}
            activeClassName={styles.activeLink}
            to='/'
          >
            Create batch
          </NavLink>
        </li>
        <li>
          <NavLink
            className={`h5 ${styles.link}`}
            activeClassName={styles.activeLink}
            to='/permissions'
          >
            Allow permissions
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
