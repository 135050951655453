import React, { useRef } from 'react';
import { STATE_PATHS, saveState } from '../utils/state';

type Props = {
  value: string;
  rows: number;
  onChange: (value: string) => void;
};

const PathsInput: React.FC<Props> = ({ value, onChange, rows }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const onPathInputChange = () => {
    let formattedValue = '';

    if (inputRef.current !== null) {
      formattedValue = inputRef.current.value;
    }

    // Remove all spaces, tabs etc
    formattedValue = formattedValue.replace(/^\s*[\r\n]/gm, '');

    onChange(formattedValue);
    saveState(STATE_PATHS, formattedValue);
  };

  return (
    <textarea
      className='h6'
      id='pathInput'
      name='pathInput'
      value={value}
      ref={inputRef}
      rows={rows}
      onChange={() => onPathInputChange()}
    />
  );
};

export default PathsInput;
