import React, { useRef } from 'react';
import { STATE_HOST, saveState } from '../utils/state';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const HostInput: React.FC<Props> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const getValue = () =>
    inputRef.current !== null ? inputRef.current.value : '';

  const onHostInputChange = () => {
    const value = getValue();
    onChange(value);
    saveState(STATE_HOST, value);
  };

  const onHostInputBlur = () => {
    let value = getValue();

    // Remove trailing slash from host
    if (value[value.length - 1] === '/') {
      value = value.slice(0, value.length - 1);
    }

    onChange(value);
    saveState(STATE_HOST, value);
  };

  return (
    <input
      className='h6'
      id='hostInput'
      name='hostInput'
      type='text'
      value={value}
      ref={inputRef}
      onChange={() => onHostInputChange()}
      onBlur={() => onHostInputBlur()}
    />
  );
};

export default HostInput;
