export const STATE_HOST = 'STATE_HOST';
export const STATE_PATHS = 'STATE_PATHS';

export const saveState = (id: string, data: string | string[]) => {
  window.localStorage.setItem(id, JSON.stringify(data));
};

export const getState = (id: string) => {
  let data = window.localStorage.getItem(id);

  if (!data) {
    return false;
  }

  return JSON.parse(data);
};
